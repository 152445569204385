<template>
	<v-container v-if="$can('create', 'Role') || $can('update', 'Role')" fluid>
		<v-card class="pa-8 mb-0">
			<v-form @submit.prevent="saveRole">
				<v-row class="mx-0 pb-0">
					<v-col cols="6" class="pa-0 pr-4">
						<p class="mb-3">Role name</p>
						<v-text-field
							v-model="role.name"
							:disabled="defaultRoles.includes(role.name)"
							placeholder="Eg. Manager"
							dense
							:error-messages="nameErrors"
							@blur="$v.role.name.$touch()"
						></v-text-field>
					</v-col>

					<v-col cols="6" class="pa-0">
						<p class="ma-0 mb-3 label">Users</p>
						<Search
							:initialData="role.users"
							placeholder="Search and select Users to assign this role"
							:url="`project/${$store.state.app.projectId}/users/search`"
							@search-result="updateUsers($event)"
						></Search>
					</v-col>

					<v-col cols="12 px-0">
						<p class="mb-3">Description</p>
						<v-text-field
							:disabled="defaultRoles.includes(role.name)"
							v-model="role.description"
							placeholder="Eg. Users with this role has access to content writing"
							:error-messages="descriptionErrors"
							@blur="$v.role.description.$touch()"
						>
						</v-text-field>
					</v-col>

					<v-col cols="12" class="pa-0">
						<p class="mb-3">Permissions</p>
						<v-row>
							<v-col cols="4" class="pl-0">
								<v-card>
									<v-list dense class="pa-0">
										<v-subheader
											class="
												primary
												white--text
												table-heading
												text-subtitle-1
											"
										>
											Modules
										</v-subheader>

										<v-list-item-group
											v-model="selectedDomain"
											color="primary"
										>
											<div
												v-for="(
													permission, i
												) in role.permissions"
												:key="i"
											>
												<v-divider></v-divider>
												<v-list-item>
													<v-list-item-content>
														<v-list-item-title
															class="text-body-2"
														>
															{{
																permission.domainTitle
															}}
														</v-list-item-title>
													</v-list-item-content>
												</v-list-item>
											</div>
										</v-list-item-group>
									</v-list>
								</v-card>
							</v-col>

							<v-col cols="8" class="pr-0">
								<v-card>
									<v-simple-table dense>
										<template v-slot:default>
											<thead
												class="primary table-heading"
											>
												<tr>
													<th
														class="
															white--text
															text-subtitle-1
														"
													>
														Actions
													</th>
													<th
														class="
															white--text
															text-subtitle-1
														"
													>
														Permission
													</th>
												</tr>
											</thead>
											<tbody>
												<tr
													v-for="item in role
														.permissions[
														selectedDomain
													].domainPermissions"
													:key="item.title"
												>
													<td class="text-body-2">
														{{ item.title }}
													</td>
													<td>
														<v-checkbox
															:disabled="
																defaultRoles.includes(
																	role.name
																)
															"
															v-model="
																item.isAllowed
															"
															class="pa-0"
														></v-checkbox>
													</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-card>
							</v-col>
						</v-row>
					</v-col>
				</v-row>

				<v-row>
					<v-card-actions class="px-0">
						<v-btn
							type="submit"
							color="primary"
							@click.prevent="saveRole()"
							:loading="isSaveButtonLoading"
						>
							Save
							<v-icon right>mdi-check</v-icon>
						</v-btn>
					</v-card-actions>
				</v-row>
			</v-form>
		</v-card>
	</v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import Search from '@/components/Search'
export default {
	mixins: [validationMixin],
	validations: {
		role: {
			name: {
				required,
				minLength: minLength(2),
				maxLength: maxLength(100),
			},
			description: {
				required,
				minLength: minLength(10),
				maxLength: maxLength(255),
			},
		},
	},
	components: {
		Search,
	},
	computed: {
		nameErrors() {
			const errors = []
			if (!this.$v.role.name.$dirty) return errors
			!this.$v.role.name.required &&
				errors.push('Please enter Role name.')
			!this.$v.role.name.minLength &&
				errors.push('Role name must be atleast 2 characters long.')
			!this.$v.role.name.maxLength &&
				errors.push('Role name must be atmost 100 characters long.')
			return errors
		},
		descriptionErrors() {
			const errors = []
			if (!this.$v.role.description.$dirty) return errors
			!this.$v.role.description.required &&
				errors.push('Please enter description.')
			!this.$v.role.description.minLength &&
				errors.push('Description must be atleast 10 characters long.')
			!this.$v.role.description.maxLength &&
				errors.push('Description must be atmost 255 characters long.')
			return errors
		},
	},
	props: {
		id: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			role: {
				name: '',
				description: '',
				users: [],
				permissions: [],
			},
			selectedDomain: 0,
			roleId: '',
			isEdit: false,
			defaultRoles: ['ADMIN', 'MANAGER', 'CONTENT_WRITER'],
			isSaveButtonLoading: false,
		}
	},
	created() {
		if (this.id) {
			this.isEdit = true
			this.roleId = this.id
			this.getRole()
		} else {
			this.getPermissions()
			this.loading = false
		}
	},
	methods: {
		getRole() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/project/${projectId}/roles/${this.roleId}`,
			}).then((response) => {
				if (response.status == 200 && response.data.success) {
					const data = response.data.data
					this.isEdit = true
					this.setRoleData(data)
				}
			})
		},
		getPermissions() {
			const projectId = this.$store.state.app.projectId
			this.axios
				.get(`/project/${projectId}/permissions`)
				.then((response) => {
					if (response.status == 200) {
						const data = response.data.permissions
						this.role.permissions = data
					}
				})
		},
		saveRole() {
			this.$v.role.$touch()
			if (!this.$v.role.$invalid || !this.$v.role.$error) {
				this.isSaveButtonLoading = true
				const projectId = this.$store.state.app.projectId
				const isEdit = this.isEdit
				const roleId = this.roleId

				this.axios({
					method: isEdit ? 'put' : 'post',
					url: isEdit
						? `/project/${projectId}/roles/${roleId}`
						: `/project/${projectId}/roles`,
					data: this.role,
				})
					.then((response) => {
						this.isSaveButtonLoading = false
						if (response.status == 200 && response.data.success) {
							const data = response.data.data
							this.$v.role.$reset()
							this.isEdit = true
							this.roleId = data.id
							this.setRoleData(data)
							this.$snackbar.notify({
								message: response.data.message,
								color: 'success',
							})
						}
					})
					.catch((err) => {
						this.isSaveButtonLoading = false
						const data = err.response.data
						this.$snackbar.notify({
							message: data.message,
							color: 'error',
						})
					})
			}
		},
		setRoleData(data) {
			this.role.name = data.name
			this.role.description = data.description
			this.role.users = data.users
			this.role.permissions = data.permissions
		},
		roleListing() {
			this.$router.push({
				name: 'roles.list',
			})
		},
		updateUsers(array) {
			this.role.users = array
		},
	},
}
</script>

<style scoped>
.table-heading {
	height: 3.1rem;
}
</style>
